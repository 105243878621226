//Import Pagas
//import default
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//Tema
import Theme from "theme";

//import global
import Input from "components/web/input";
import Button from "components/web/button";
// import NavBar from "components/web/navbar";

//images
// import OctalLogo from "./midia/images/8D/logo.svg";

//pages
import PersonalData from "./processRegister/personalData";
import CompanyData from "./processRegister/companyData";
import AccessData from "./processRegister/accessData";

export default ({ viewForm, setViewForm }) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const [localView, setLocalView] = useState("PersonalData");

  const contentRegister = {
    localView,
    setLocalView,
  };

  const PagesRegister = [
    PersonalData(contentRegister),
    CompanyData(contentRegister),
    AccessData(contentRegister),
  ];

  useEffect(async () => {
    setLocalView("PersonalData");
  }, [viewForm]);

  return {
    render: (
      <Fade>
        {Render(
          // form login
          {
            component: "container",
            style: {
              display: viewForm === "RegisterClient" ? "flex" : "none",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            },
            children: PagesRegister,
          }
        )}
      </Fade>
    ),
  };
};
