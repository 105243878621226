import { Render } from "@8_dev/sjson-web";

// import { Render } from "render";

import { useEffect, useState } from "react";
import { ShowPage } from "context/showPage";

//components
import Navbar from "components/web/navbar";

//images e icons
import Home from "assets/images/octal/menu/home.svg";
import Members from "assets/images/octal/menu/members.svg";

export default () => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();

  useEffect(async () => {}, []);

  // let Pages = [];
  const Nav = {
    component: "container",
    style: {
      display: "flex",
      height: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    children: [
      Navbar({
        style: {
          boxMain: {
            maxHeight: "98%",
            height: "100%",
            borderRadius: 0,
            borderRight: "1px solid gray",
            boxShadow: "",
          },
          menu: {
            marginTop: 12,
          },
        },
        item: [
          // Home
          {
            title: "Início",
            page: "Home",
            image: {
              src: Home,
            },
          },
          // Pedidos
          {
            title: "Pedidos",
            page: "order-buy",
            image: {
              src: Members,
            },
          },
        ],
      }),
    ],
  };

  const PageStyle = {
    display: "flex",
    flexDirection: "row",
    width: "100vw",
    minWidth: "100vw",
    maxWidth: "100vw",
    height: "100vh",
    maxHeight: "100vh",
    minHeight: "100vh",
    overflow: "hidden",
    alignItems: "center",
  };

  // Pages.push(Nav);
  // Pages.concat(Business());
  const Pages = [
    Nav,
    {
      component: "container",
      style: {
        display: "flex",
        width: "100%",
        height: "100%",
      },
    },
  ];
  return {
    component: "container",
    style: PageStyle,
    children: Pages,
  };
};
