//import default
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//Tema
import Theme from "theme";

//import global
import Input from "components/web/input";
import Button from "components/web/button";
// import NavBar from "components/web/navbar";

//images
// import OctalLogo from "media/images/8D/logo.svg";

export default ({ viewForm, setViewForm }) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(async () => {}, []);

  return {
    render: (
      <Fade>
        {Render(
          // form login
          {
            component: "container",
            style: {
              display: viewForm === "EmailSent" ? "flex" : "none",
              width: "100%",
              paddingBottom: 38,
              gap: 24,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            },
            children: [
              //text Login admin
              {
                component: "text",
                text: "Email enviado!",
                style: {
                  fontSize: "1.44vw",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  marginBottom: 24,
                },
              },
              //text octalDev
              {
                component: "container",
                style: {
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: 4,
                },
                children: [
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: 4,
                    },
                    children: [
                      {
                        component: "text",
                        style: {
                          display: "flex",
                          fontSize: "0.66vw",
                          fontFamily: "Poppins",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#757575",
                        },
                        text: "Acesse o email",
                      },
                      {
                        component: "text",
                        style: {
                          display: "flex",
                          fontSize: "0.66vw",
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#757575",
                        },
                        text: "bruninho21003@gmail.com,",
                      },
                      {
                        component: "text",
                        style: {
                          display: "flex",
                          fontSize: "0.66vw",
                          fontFamily: "Poppins",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#757575",
                        },
                        text: "verifique",
                      },
                    ],
                  },
                  {
                    component: "text",
                    style: {
                      display: "flex",
                      fontSize: "0.66vw",
                      fontFamily: "Poppins",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#757575",
                    },
                    text: "sua caixa de mensagem e siga as instruções",
                  },
                ],
              },
              //btnEntrar
              {
                component: "container",
                style: {
                  width: "80%",
                  height: 64,
                  padding: 4,
                  marginTop: 24,
                },
                children: [
                  Button({
                    style: {
                      button: {
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#212121",
                        border: "none",
                        borderRadius: 8,
                      },
                      label: {
                        fontSize: "0.8vw",
                        fontFamily: "Poppins",
                        color: "white",
                      },
                    },
                    label: "VOLTAR PARA LOGIN",
                    onPress: async () => {
                      setViewForm("LoginForm");
                    },
                  }),
                ],
              },
            ],
          }
        )}
      </Fade>
    ),
  };
};
