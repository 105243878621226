import React, { createContext, useContext, useEffect, useState } from "react";
import theme from "../theme";

const Data = createContext();

export default function DataContext({ children }) {
  const [update, setUpdate] = useState("");

  const [data, setData] = useState({
    init: "Home",
    last: "",
    history: [],
    page: {},
    show: "",
    param: {},
    requestData: {},
  });

  return (
    <Data.Provider
      value={{
        data,
        setData,
        update,
        setUpdate,
      }}
    >
      {children}
    </Data.Provider>
  );
}

export function ShowPage() {
  const contextData = useContext(Data);
  const { data, setData, update, setUpdate } = contextData;

  return {
    PageInfo: data,
    Set: setData,
    ClearRequestData: () => {
      setData({
        ...data,
        requestData: {},
      });
    },
    SetRequestData: (data) => {
      setData({
        ...data,
        requestData: data,
      });
    },
    // carregar uma pagina que esteja no historico e apagar historios anteriores ate chegar na pagina
    GoInitPage: async () => {
      setData({
        ...data,
        history: [],
        show: data.init,
      });
    },
    GoTo: async (page, param) => {
      // caso nao tenha no historico nao fazer nada
      if (!param) param = {};
      if (!data.history.includes(page)) return;
      setData({
        ...data,
        history: data.history.slice(0, data.history.indexOf(page) + 1),
        show: page,
        param,
      });
    },
    GoPage: async (page, param) => {
      console.log(page);
      if (!param) param = {};
      setData({
        ...data,
        history: [...data.history, page],
        show: page,
        param,
      });
    },
    GoBack: async () => {
      // caso nao tenha no historico voltar pro init
      if (data.history.length <= 1) {
        setData({
          ...data,
          history: [],
          show: data.init,
        });
        return;
      }
      setData({
        ...data,
        show: data.history[data.history.length - 2],
        history: data.history.slice(0, data.history.length - 1),
      });
    },
    // setUpdate: async (id) => {
    //   // console.log("verAq", id, data.update);
    //   setData({
    //     ...data,
    //     update: id,
    //   });
    // },
    // update: data.update,
    setUpdate,
    update,
  };
}
