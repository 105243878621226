import "dotenv";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import Core from "@8_dev/front_web_lbry";
import { useAlert } from "./alertContext";
import { Context } from "./data";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

export const SocketContext = createContext();

// criar objeto de funcoes do notify

let sockNotify = {};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default function SocketProvider({ children }) {
  const {
    errorPopUp,
    setErrorPopUp,
    setAlertMsg,
    setAlertTitle,
    setSnackMsg,
    setSnackTitle,
    setSnackAlert,
    setCloseTime,
  } = useAlert();

  const { Data, Set, SetLoading } = Context();
  const { GoInitPage, setUpdate } = ShowPage();

  const { PopupShow, PopupClose } = ShowPopup();

  const apiURL = process.env.REACT_APP_API_URL;

  const socket = useRef(null);
  const [connected, setConnected] = useState(false);

  const handleEmitMessage = async (action, info) => {
    let token = localStorage.getItem("token");

    if (!token) return;

    socket.current.emit("message", {
      action,
      token,
      info,
    });
  };

  const handleEvents = (action, fn) => {
    socket.current.on(`_message@${action}`, fn);
  };

  const connectWebsocket = async () => {
    socket.current = await Core.module.server.socket.connect(apiURL);

    socket.current.on("connect", () => {
      setConnected(true);

      let token = localStorage.getItem("token");

      if (!token) return;

      SetLoading(true);
      socket.current.emit("message", {
        action: "auth",
        info: {
          token: token,
        },
      });
    });

    socket.current.on("disconnect", async () => {
      SetLoading(true);
      console.log("Socket disconnected");
    });

    handleEvents("auth/signin", async (data) => {
      await sleep(1000);
      console.log("auth/signin", data);
      if (data.status === true && data.info?.data?.data?.user?.info) {
        Set({
          ...Data,
          user: data.info.data.data.user.info,
        });
        // salvar token no localstorage
        localStorage.setItem("token", data.info.data.data.token);
        GoInitPage();

        await sleep(500);
        SetLoading(false);
      }
    });

    handleEvents("notify", async (data) => {
      console.log("notifyyUpdate", data);
      PopupShow(
        {
          title: data.info.title,
          description: data.info.msg,
          // onConfirm: () => {
          //   // clear();
          // },
          // onCancel: () => {
          //   // clear();
          //   // GoBack();
          // },
        },
        "time",
        data.info.time || 2000
      );
      // console.log(sockNotify[data])

      // criar um componente de notificacao lateral
      // com titulo , time e descricao

      // setSnackMsg(data.info.msg);
      // setSnackTitle(data.info.title);
      // setCloseTime(data.info.time);
      // setSnackAlert(true);
      setUpdate("order-buy" + new Date());
    });
    handleEvents("alert", async (data) => {
      setAlertMsg(data.info.msg);
      setAlertTitle(data.info.title);
      setErrorPopUp(true);
    });
  };

  useEffect(() => {
    connectWebsocket();

    return () => {
      socket.current.close();
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
        handleEmitMessage,
        handleEvents,
        connected,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

// exportar socket facil de usar para enviar mensagem e stream na escuta
// exportar função para escutar eventos
export const useSocket = () => {
  const { socket, handleEmitMessage, handleEvents, connected } =
    useContext(SocketContext);

  return {
    sock: socket,
    send: handleEmitMessage,
    event: handleEvents,
    connected,
  };
};
