import React, { createContext, useContext, useEffect, useState } from "react";
import theme from "../theme";

const Data = createContext();

export default function PopupContext({ children }) {
  const [data, setData] = useState({
    info: {
      title: "",
      description: "",
      onClose: () => {},
      onConfirm: () => {},
      onCancel: () => {},
    },
    show: false,
    type: "",
    time: 0,
  });

  return (
    <Data.Provider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </Data.Provider>
  );
}

export function ShowPopup() {
  const contextData = useContext(Data);
  const { data, setData } = contextData;
  return {
    PopupTime: data.time,
    PopupType: data.type,
    PopupInfo: data.info,
    PopupStatus: data.show,
    PopupClose: async () => {
      setData({
        info: {},
        show: false,
      });
    },
    PopupShow: async (param, type, time) => {
      if (!time) time = 1000;
      if (!type) type = "normal";
      setData({
        ...data,
        info: param,
        show: true,
        type,
        time,
      });
    },
  };
}
