import React, { createContext, useContext, useEffect, useState } from "react";
import theme from "../theme";
const AlertContext = createContext();

export default function AlertProvider({ children }) {
  const [suggestPopUp, setSuggestPopUp] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [snackAlert, setSnackAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [snackMsg, setSnackMsg] = useState("");
  const [snackTitle, setSnackTitle] = useState("");
  const [closeTime, setCloseTime] = useState("");

  return (
    <AlertContext.Provider
      value={{
        suggestPopUp,
        setSuggestPopUp,
        errorPopUp,
        setErrorPopUp,
        alertMsg,
        setAlertMsg,
        alertTitle,
        setAlertTitle,
        snackTitle,
        setSnackTitle,
        snackMsg,
        setSnackMsg,
        snackAlert,
        setSnackAlert,
        closeTime,
        setCloseTime,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
}

export function useAlert() {
  const context = useContext(AlertContext);
  const {
    suggestPopUp,
    setSuggestPopUp,
    errorPopUp,
    setErrorPopUp,
    alertTitle,
    setAlertTitle,
    alertMsg,
    setAlertMsg,
    snackTitle,
    setSnackTitle,
    snackMsg,
    setSnackMsg,
    snackAlert,
    setSnackAlert,
    closeTime,
    setCloseTime,
  } = context;
  return {
    suggestPopUp,
    setSuggestPopUp,
    errorPopUp,
    setErrorPopUp,
    alertTitle,
    setAlertTitle,
    alertMsg,
    setAlertMsg,
    snackTitle,
    setSnackTitle,
    snackMsg,
    setSnackMsg,
    snackAlert,
    setSnackAlert,
    closeTime,
    setCloseTime,
  };
}
