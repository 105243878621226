import React, { createContext, useContext, useEffect, useState } from "react";
import theme from "../theme";

const Data = createContext();
const Loading = createContext();

export default function DataContext({ children }) {
  const [data, setData] = useState({
    user: { status: false },
  });

  const [loading, setLoading] = useState(false);

  return (
    <Loading.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      <Data.Provider
        value={{
          data,
          setData,
        }}
      >
        {children}
      </Data.Provider>
    </Loading.Provider>
  );
}

function GetData() {
  const contextData = useContext(Data);
  const { data } = contextData;
  return data;
}

function UpData(value) {
  const contextData = useContext(Data);
  const { data, setData } = contextData;
  setData({ ...data, ...value });
}

function UpKeyData(key, value) {
  const contextData = useContext(Data);
  const { data, setData } = contextData;
  setData({ ...data, [key]: value });
}

function AddData(value) {
  const contextData = useContext(Data);
  const { data, setData } = contextData;
  setData({ ...data, ...value });
}

function RemoveData(value) {
  const contextData = useContext(Data);
  const { data, setData } = contextData;
  setData({ ...data, ...value });
}

export function Context() {
  const contextData = useContext(Data);
  const contextLoading = useContext(Loading);
  const { data, setData } = contextData;
  const { loading, setLoading } = contextLoading;

  return {
    Data: data,
    Set: setData,
    Up: UpData,
    Loading: loading,
    SetLoading: setLoading,
    Add: AddData,
    Remove: RemoveData,
    UpKey: UpKeyData,
  };
}
