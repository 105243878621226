import React, { createContext, useContext, useEffect, useState } from "react";
import api from "../service";
import Core from "@8_dev/front_web_lbry";
import { useSocket } from "../hooks/useSocket";
const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const { handleEmitMessage } = useSocket();
  const [token, setToken] = useState("");
  const [user, setUser] = useState(null);

  // let socket = Core.module.server.socket.connect(
  //   "https://space-petin.homelinux.com:25001"
  // );

  // socket.on("connect", async () => {
  //   socket.emit("message", {
  //     action: "auth",
  //     info: {
  //       token: await localStorage.getItem("token"),
  //     },
  //   });
  // });

  let autoLogin = async (navigation, path) => {
    let token = await localStorage.getItem("token");
    let type = await localStorage.getItem("type");
    let user = await localStorage.getItem("user");
    if (token && type && user) {
      setUser(JSON.parse(user));

      switch (type) {
        case "manager":
          return navigation(path ? path : "/dashboard-admin");
        case "provider":
          return navigation(path ? path : "/dashboard-provider");
        case "business":
          return navigation(path ? path : "/dashboard-business");
        default:
          return;
      }
    }
    return token;
  };
  let handleLogin = async (token, type, user, navigation) => {
    await localStorage.setItem("token", token);
    await localStorage.setItem("type", type);
    await localStorage.setItem("user", JSON.stringify(user));
    handleEmitMessage("auth", { token });
    setToken(token);
    setUser(user);
    switch (type) {
      case "manager":
        return navigation("/dashboard-admin");
      case "provider":
        return navigation("/dashboard-provider");
      case "business":
        return navigation("/dashboard-business");
      default:
        return;
    }
  };
  return (
    <AuthContext.Provider
      value={{
        // socket,
        token,
        setToken,
        handleLogin,
        autoLogin,
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  const { socket, token, setToken, handleLogin, autoLogin, user, setUser } =
    context;
  return { socket, token, setToken, handleLogin, autoLogin, user, setUser };
}
