//default imports
import { Render } from "@8_dev/sjson-web";
import Theme from "theme";
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";

//Context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//components
//import PopUp from "/util/components/web/popup";

// Assets dessa pagina

// Componente Global -> /lib/global/components/web/button.
// Componente Web -> /lib/web/components/web/button.
// Componente Local -> /lib/local/components/web/button.

//pages
import Login from "pages/access/login";
import Intro from "pages/intro";

let Page = () => {
  const { Data, Set } = Context();
  const Navigation = ShowPage();

  useEffect(async () => {}, []);

  return Render({
    component: "container",
    style: {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      maxHeight: "100vh",
      minWidth: "100vw",
      minHeight: "100vh",
    },
    children: [
      // Conteudo das Paginas
      // PopUp(),
      Login(),
      {
        render: (
          <div
            style={{
              position: "absolute",
              display: Data.user?.status ? "flex" : "none",
            }}
          >
            <Fade>
              {Render({
                component: "container",
                style: {
                  width: "100vw",
                  height: "100vh",
                  position: "absolute",
                  // backgroundColor: Theme.colors.black,
                },
                children: [Intro()],
              })}
            </Fade>
          </div>
        ),
      },
    ],
  });
};

export default Page;
