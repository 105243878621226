import http, { Axios } from "axios";

let baseURL = process.env.REACT_APP_API_URL + "/api";
let CEP_BASE_URL = "http://cep.la/";

let publicCEP = http.create({
  baseURL: CEP_BASE_URL,
  headers: {
    Accept: "application/json",
  },
});

let publicHttp = http.create({
  baseURL,
});
let privateHttp = http.create({
  baseURL,
});

export { publicHttp, privateHttp, publicCEP, baseURL };
