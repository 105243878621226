// criar state para modo escuro
// criar state para tamanho da fonte
// criar state para tamanho da tela

const colors = {
  primary: {
    regular: "#211E40",
    medium: "#605E7D",
    light: "#A3A1B7",
    extra_ligth: "#E7E7EE",
    dark: "#0D0B25",
    extra_dark: "#060414",
  },
  regular: {
    regular: "#00FFC3",
    medium: "#99FFF9",
    light: "#BFFFFE",
    extra_ligth: "#E6FFFF",
    dark: "#008174",
    extra_dark: "#00423F",
  },
  neutral: {
    regular: "#848484",
    medium: "#A8A8A8",
    light: "#D2D2D2",
    extra_ligth: "#FFFFFF",
    dark: "#3A3A3A",
    extra_dark: "#000000",
  },
  sucess: {
    regular: "#00A468",
    medium: "#73CDAC",
    light: "#99DBC3",
    extra_ligth: "#E6F6F0",
    dark: "#00422A",
    extra_dark: "#00100A",
  },
  warning: {
    regular: "#FFD300",
    medium: "#FFE773",
    light: "#FFED99",
    extra_ligth: "#FFFBE6",
    dark: "#665400",
    extra_dark: "#1A1500",
  },
  error: {
    regular: "#C40234",
    medium: "#D64E71",
    light: "#E79AAE",
    extra_ligth: "#F9E6EB",
    dark: "#4E0115",
    extra_dark: "#140005",
  },
  info: {
    regular: "#0188BF",
    medium: "#73BEDC",
    light: "#99CFE5",
    extra_ligth: "#E6F3F9",
    dark: "#00364C",
    extra_dark: "#000E13",
  },
  bg: {
    light: {
      top: "#19C5FF",
      bottom: "#000000",
    },
    dark: {
      top: "#02294A",
      bottom: "#000000",
    },
  },
  nav: {
    moon: "#86FFFF",
    sun: "#FF9700",
  },
};

const elevations = {
  1: "0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)",
  2: "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)",
  3: "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)",
  4: "0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)",
  5: "0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16)",
  6: "0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24)",
};

const fontFamilies = {
  primary: "Poppins",
  secondary: "Poppins",
  body: "Poppins",
  label: "Poppins", // Mantendo Roboto para consistência
};

const fontSizes = {
  title: "0.88vw",
  button: "0.64vw",
  subTitle: "0.68vw",
  tiny: "0.36vw",
  small: "0.45vw",
  normal: "1vh",
  focus: "1.2vh",
  capitalized: "1.4vh",
  blockquotes: "0.76vw",
  body14: "0.44vw",
  body16: "0.64vw",
  lead: "0.40vw",
  big: "1vw",
  huge: "1.4vw",
  col: "1.4vh",
  row: "0.82vw",
  form: {
    title: "1.8vh",
    input: "1vh",
  },
  hugeTitle: 24,
  bigTitle: 16,
};

const padding = {
  small: 5,
  normal: 10,
  medium: 15,
  big: 35,
};

const margin = {
  small: 5,
  normal: 10,
  medium: 15,
  big: 35,
};

const opacity = {
  0: "09",
  10: "0.1",
  20: "0.2",
  30: "0.3",
  40: "0.4",
  50: "0.5",
  60: "0.6",
  70: "0.7",
  80: "0.8",
  90: "0.9",
  100: "1",
  101: "2",
};


export default {
  colors,
  fontFamilies,
  fontSizes,
  padding,
  margin,
  elevations,
  opacity,
};
