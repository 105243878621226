// Icon component
import Theme from "theme";
import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

import ClickSound from "assets/sounds/click.mp3";

import { Howl } from "howler";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const soundPlay = async (src, time, interval) => {
  const sound = new Howl({
    src,
    html5: true,
    volume: 0.5,
    loop: false,
  });
  for (let i = 0; i < time; i++) {
    await delay((interval || 0) * i);
    sound.play();
  }
};

export default (param) => {
  // const [isHovered, setIsHovered] = useState(false);

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  return {
    component: "container",
    style: {
      width: "100%",
      height: "100%",
      aspectRatio: "1/1",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      gap: 4,
      ...param?.style?.iconBox,
    },
    // onMouseEnter: handleMouseEnter,
    // onMouseLeave: handleMouseLeave,
    onClick: async () => {
      soundPlay(ClickSound, 1);
      if (param?.onPress)
        try {
          await param.onPress();
        } catch (err) {
          console.log("icon err", err);
        }
    },
    children: [
      {
        render: (
          <img
            style={{
              display: param.src ? "flex" : "none",
              width: "auto",
              height: param.size || "22%",
              alignItems: "center",
              justifyContent: "center",
              aspectRatio: "1/1",
              transition: "transform 0.1s ease",
              ...param?.style?.image,
            }}
            src={param.src || ""}
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
          />
        ),
      },
      {
        render: (
          <Fade top left>
            {Render({
              component: "text",
              text: param?.title || "",
              style: {
                display: param.title?.length > 0 ? "flex" : "none",
                fontSize: "1.32vh",
                flex: 1,
                alignItems: "flex-start",
                textAlign: "flex-start",
                justifyContent: "flex-start",
                fontFamily: Theme.fontFamilies.secondary,
                ...param?.style?.title,
              },
            })}
          </Fade>
        ),
      },
    ],
  };
};
