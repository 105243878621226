import React from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

import Init from "./pages/init";
import ActiveProvider from "./context/activeContext";
import AuthProvider from "./context/authContext";
import DataContext from "./context/data";
import PageContext from "./context/showPage";
import ShowPopup from "./context/popup";
import AlertProvider, { useAlert } from "./context/alertContext";
import SocketProvider from "./context/socketContext";

// const isLoggedAsManager = () =>
//   !!localStorage.getItem("token") && localStorage.getItem("type") == "manager";

// const PrivateRouteManager = () => {
//   return isLoggedAsManager() ? <Outlet /> : <Navigate to="/" />;
// };

export default function App() {
  return (
    <PageContext>
      <DataContext>
        <ShowPopup>
          <AlertProvider>
            <SocketProvider>
              <AuthProvider>
                <ActiveProvider>
                  <Router>
                    <Switch>
                      <Route exact path="/" element={<Init />} />
                    </Switch>
                  </Router>
                </ActiveProvider>
              </AuthProvider>
            </SocketProvider>
          </AlertProvider>
        </ShowPopup>
      </DataContext>
    </PageContext>
  );
}
