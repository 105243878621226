//import default
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//Tema
import Theme from "theme";

//import global
import Input from "components/web/input";
import Button from "components/web/button";
// import NavBar from "components/web/navbar";

//images
// import OctalLogo from "./midia/images/8D/logo.svg";

export default ({ viewForm, setViewForm }) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const inputStyle = {
    input: {
      width: "100%",
      height: 48,
      border: "1px solid #bfbfbf",
      borderRadius: 8,
      paddingLeft: 12,
      fontFamily: "Poppins",
    },
    title: {
      fontFamily: "Poppins",
      fontSize: "0.66vw",
    },
  };

  const inptUsername = Input({
    title: "Usuário",
    value: username,
    setValue: setUsername,
    // mask: "99.999.999/9999-99",
    style: {
      ...inputStyle,
    },
  });

  const inptPassword = Input({
    type: "password",
    title: "Senha",
    value: password,
    setValue: setPassword,
    style: {
      ...inputStyle,
    },
  });

  useEffect(async () => {}, []);

  return {
    render: (
      <Fade>
        {Render(
          // form login
          {
            component: "container",
            style: {
              display: viewForm === "LoginForm" ? "flex" : "none",
              width: "100%",
              paddingBottom: 38,
              gap: 24,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            },
            children: [
              //text octalDev
              {
                component: "container",
                style: {
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  gap: 4,
                },
                children: [
                  {
                    component: "text",
                    style: {
                      display: "flex",
                      fontSize: "1.44vw",
                      fontFamily: "Poppins",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "400",
                    },
                    text: "Faça login como cliente ",
                  },
                  {
                    component: "text",
                    style: {
                      display: "flex",
                      fontSize: "1.44vw",
                      fontFamily: "Poppins",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#9998E4",
                    },
                    text: "{OctalDev}",
                  },
                ],
              },
              //text Login client
              {
                component: "text",
                text: "Automatize, gerencie e evolua seu negócio.",
                style: {
                  fontSize: "0.66vw",
                  fontFamily: "Poppins",
                  color: "#757575",
                },
              },
              //inputs
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "80%",
                  flexDirection: "column",
                  marginTop: 12,
                  gap: 8,
                },
                children: [inptUsername, inptPassword],
              },
              //recoveryPassword
              {
                component: "text",
                style: {
                  display: "flex",
                  fontSize: "0.66vw",
                  fontFamily: "Poppins",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#757575",
                  textDecoration: "underline",
                  cursor: "pointer",
                },
                text: "Esqueceu sua senha?",
                onClick: () => {
                  setViewForm("RecoveryPassword");
                },
              },
              //btnEntrar
              {
                component: "container",
                style: {
                  width: "80%",
                  height: 64,
                  padding: 4,
                },
                children: [
                  Button({
                    style: {
                      button: {
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#212121",
                        border: "none",
                        borderRadius: 8,
                      },
                      label: {
                        fontSize: "0.8vw",
                        fontFamily: "Poppins",
                        color: "white",
                      },
                    },
                    label: "ENTRAR",
                    onPress: async () => {
                      Set({
                        ...Data,
                        user: {
                          status: true,
                        },
                      });
                      GoInitPage();
                      // const { data } = await api.auth.login({
                      //   username: username,
                      //   password: password,
                      //   type: "client",
                      // });
                      // await sleep(500);
                      // if (data?.status === true) {
                      //   await localStorage.setItem("token", data.data.token);
                      // } else {
                      //   alert("erro");
                      // }
                    },
                  }),
                ],
              },
            ],
          }
        )}
      </Fade>
    ),
  };
};
