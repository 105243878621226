//import default
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//Tema
import Theme from "theme";

//import global
import Input from "components/web/input";
import Button from "components/web/button";
// import NavBar from "components/web/navbar";

//images
// import OctalLogo from "./midia/images/8D/logo.svg";

export default ({ localView, setLocalView }) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const [code, setCode] = useState("");
  const [companyName, setCompanyName] = useState("");

  const inputStyle = {
    input: {
      width: "100%",
      height: 48,
      border: "1px solid #bfbfbf",
      borderRadius: 8,
      paddingLeft: 12,
      fontFamily: "Poppins",
    },
    title: {
      fontFamily: "Poppins",
      fontSize: "0.66vw",
    },
  };

  const inptCode = Input({
    title: "CPF",
    value: code,
    setValue: setCode,
    // mask: "99.999.999/9999-99",
    style: {
      ...inputStyle,
    },
  });

  const inptCompanyName = Input({
    type: "password",
    title: "Razão social (opcional)",
    value: companyName,
    setValue: setCompanyName,
    style: {
      ...inputStyle,
    },
  });

  useEffect(async () => {}, []);

  return {
    render: (
      <Fade>
        {Render(
          // form login
          {
            component: "container",
            style: {
              display: localView === "CompanyData" ? "flex" : "none",
              width: "100%",
              paddingTop: 24,
              paddingBottom: 38,
              gap: 24,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            },
            children: [
              //text stepRegister
              {
                component: "text",
                text: "Dados da empresa",
                style: {
                  display: "flex",
                  fontSize: "1vw",
                  fontFamily: "Poppins",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#757575",
                },
              },
              //inputs
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "80%",
                  flexDirection: "column",
                  marginTop: 12,
                  gap: 8,
                },
                children: [inptCode, inptCompanyName],
              },
              //btnEntrar
              {
                component: "container",
                style: {
                  width: "80%",
                  height: 64,
                  padding: 4,
                },
                children: [
                  Button({
                    style: {
                      button: {
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#212121",
                        border: "none",
                        borderRadius: 8,
                      },
                      label: {
                        fontSize: "0.8vw",
                        fontFamily: "Poppins",
                        color: "white",
                      },
                    },
                    label: "AVANÇAR",
                    onPress: async () => {
                      setLocalView("AccessData");
                    },
                  }),
                ],
              },
            ],
          }
        )}
      </Fade>
    ),
  };
};
